.event {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  margin: 20px 0;
  padding: 0px 30px;
  text-align: left;
  font-size: 20px;
}

.event-ul{
 margin: 0px;
}

.event-ul-li{
 margin: 0px;
}

.event-image {
  display: flex;
  width: 100%;
  height: 100%;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.event-image img {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
}
 
